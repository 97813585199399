<template>
  <div>
    <el-row  type="flex" justify="center" style="margin-top: 2%;" >
      <el-col :span="5" >
        <el-card style="text-align: center;">
          <img
            src="@/assets/chuji.png"
            class="image"
            style="width: 30%"
          />
          <div >
            <span>初级会计师</span>
          </div>
          <!-- <div> -->
            <el-button style="margin-top: 10%;width: 80%;margin-left: 0;" round v-for="(item,index) in chuji" :key="index" @click="changeTiku(item)"> {{ item.name }}</el-button>
          <!-- </div> -->

        </el-card>
      </el-col>
      <el-col  :span="6" style="margin-left: 3%;margin-right: 3%;">
        <el-card style="text-align: center;">
          <img
            src="@/assets/zhongji.png"
            class="image"
            style="width: 26%"
          />
          <div >
            <span>中级会计师</span>
          </div>
          <!-- <div style="width: 60%;float: right;height: 100%;"> -->
            <el-button style="margin-top: 5%;margin-left: 0;width: 80%;" round v-for="(item,index) in zhongji" :key="index" @click="changeTiku(item)"> {{ item.name }}</el-button>
          <!-- </div> -->
        </el-card>
      </el-col>
      <el-col  :span="11"> 
        <el-card style="text-align: center;">
          <img
            src="@/assets/zhuce.png"
            class="image"
            style="width: 14%"
          />
          <div >
            <span>注册会计师</span>
          </div>
          <!-- <div style="width: 70%;float: right;height: 100%;"> -->
            <el-button style="margin-top: 3%;width: 30%;margin-left: 0;margin-right: 3%;" round v-for="(item,index) in zhuce" :key="index"> {{ item.name }}</el-button>
          <!-- </div> -->
          
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { TreeList } from "@/api/example"

export default {
  name: 'example',

  data() {
    return {
      chuji:[{
        name:'初级会计实务',
        id:3
      },
      {
        name:' 经济法基础 ',
        id:4
      }],
      zhongji:[{
        name:'中级财务管理',
        id:5
      },
      {
        name:'中级会计实务',
        id:6
      },
      {
        name:'中级经济法',
        id:7
      }],
      zhuce:[{
        name:'审计',
        id:2
      },
      {
        name:'税法',
        id:2
      },
      {
        name:'会计',
        id:2
      },
      {
        name:'经济法',
        id:3
      },
      {
        name:'财务成本管理',
        id:3
      },
      
      {
        name:'战略与风险管理',
        id:3
      },
      {
        name:'综合一',
        id:2
      },
      {
        name:'综合二',
        id:3
      }],
      contentStyleObj:{}, //高度变化

    };
},
  created(){
    this.contentStyleObj=this.$getHeight(380)
    this.init()
  },

  mounted() {
    
  },

  methods: {
    init(){
      TreeList({level:1}).then(res => {
        // this.tableData1 = res.data.data.list
        // this.chuji = res.data.data
        // this.zhongji = res.data.data
        // this.zhuce = res.data.data
      })
    },

    changeTiku(item){
      this.$store.dispatch('commons/setParam', {exampleId: item.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "example2")

      this.$router.push({
        path: "/example/example2",
      });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>